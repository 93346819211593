import React from 'react'
import ServicesOne from '../components/Services Page/servicesOne'
import Navbar from '../components/navbar'
import ServicesTwo from '../components/Services Page/servicesTwo'
import ServicesThree from '../components/Services Page/servicesThree'
import ServicesMain from '../components/Services Page/servicesMain'
import Footer from '../components/footer'

export default function Services() {
  return (
    <div>
        <Navbar/>
        <ServicesMain/>
        <Footer/>
        {/* <ServicesTwo/> */}
        
   
    </div>
  )
}
